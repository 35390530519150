import React from "react"
import Banner from "./Banner"
import Content from "./Content"

const Main = () => (
  <>
    <Banner />
    <Content />
  </>
)

export default Main
