import React, { useState } from "react"
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav as RSNav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Nav = props => {
  const [isOpen, openToggle] = useState(false)

  return (
    <Row>
      <Col>
        <Navbar className="bg-light" expand="md">
          <NavbarBrand className="text-monospace">
            {"<"}Pensacode{"/>"}
          </NavbarBrand>
          <NavbarToggler onClick={() => openToggle(!isOpen)}>
            <FontAwesomeIcon icon={faBars} />
          </NavbarToggler>
          <Collapse navbar={true} isOpen={isOpen}>
            <RSNav navbar={true} className="ml-auto">
              <NavItem>
                <NavLink>Item 1</NavLink>
              </NavItem>
              <NavItem>
                <NavLink>Item 2</NavLink>
              </NavItem>
              <NavItem>
                <NavLink>Item 3</NavLink>
              </NavItem>
            </RSNav>
          </Collapse>
        </Navbar>
      </Col>
    </Row>
  )
}

export default Nav
