import React from "react"
import { Container } from "reactstrap"
import Nav from "./Nav"
import Main from "./Main"
import Footer from "./Footer"

const App = () => {
  return (
    <Container fluid={true}>
      <Nav />
      <Main />
      <Footer />
    </Container>
  )
}

export default App
