import React from "react"
import { Row, Col } from "reactstrap"

const Footer = props => (
  <Row>
    <Col>
      <footer className="bg-light px-2">
        <small className="text-muted">&copy; 2019 Pensacode.</small>
      </footer>
    </Col>
  </Row>
)

export default Footer
