import React from "react"
import { Row, Col, Jumbotron } from "reactstrap"
import laptop from "./laptop_on_table.jpg"

const Banner = props => (
  <Row>
    <Col>
      <Jumbotron
        className="mb-0"
        style={{
          backgroundImage: `url(${laptop})`,
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        <h1 className="px-2 display-4 bg-white shadow-lg text-black text-monospace">
          It's time to level-up.
        </h1>
        <p className="px-2 lead bg-white shaow-lg text-black text-monospace">
          Learn modern web development in one on one or small-group lessons.
        </p>
      </Jumbotron>
    </Col>
  </Row>
)

export default Banner
