import React from "react"
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap"

const Content = () => (
  <Row>
    <Col>
      <Card>
        <CardBody>
          <h5>Pensacola, it's time to take your skills to the next level.</h5>
          <p>Some text content.</p>
        </CardBody>
      </Card>
    </Col>
  </Row>
)

export default Content
